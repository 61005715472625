.carousel {
  height: 100%;
  /* background-color: aquamarine; */
  margin: auto;
}

.carousel-control-prev {
  height: 300px;
  visibility: hidden;
}

.carousel-control-next {
  height: 300px;
  visibility: hidden;
}

.carousel .carousel-control-prev-icon {
  visibility: hidden;
}

.carousel:hover .carousel-control-prev-icon {
  visibility: hidden;
}

.carousel .carousel-control-next-icon {
  visibility: hidden;
}

.carousel:hover .carousel-control-next-icon {
  visibility: hidden;
}

.carouselText h3 {
  font-family: 'LuloClean', sans-serif;
  color: white;
  font-size: 25pt;
  /* text-shadow: 1px 0 black, -1px 0 black, 0 1px black, 0 -1px black,
               1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black; */
}

.carouselText h4 {
  font-family: 'LuloClean', sans-serif;
  color: white;
  padding-bottom: 2%;
  /* text-shadow: 1px 0 black, -1px 0 black, 0 1px black, 0 -1px black,
               1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black; */
  font-size: 2vw;
}

.carouselText p {
  font-family: 'Arial', sans-serif;
  color: white;
}

/* @font-face {
  font-family: 'LuloClean';
  src: url(../../assets/fonts/LuloClean.ttf);
} */

video {
  filter: brightness(70%);
}

@media only screen and (max-width: 1200px) {
  .carousel-caption{
    display: none;
  }
  .carousel-indicators{
    display: none;
  }

}
@media only screen and (max-width: 920px){
  .bottomText{
    display: none;
  }
}
.fit {
  white-space: wrap;
}
.resHeader p{
  font-family: 'Raleway', sans-serif;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 11pt;
}
.carousel-indicators{
  display: none;
}