@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;500&display=swap);
*,
*::after,
*::before{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}


#payment-form {
    border: #F6F9FC solid 1px;
    border-radius: var(--radius);
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
  }
  
  #messages {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
    display: none; /* hide initially, then show once the first message arrives */
    background-color: #0A253C;
    color: #00D924;
    padding: 20px;
    margin: 20px 0;
    border-radius: var(--radius);
    font-size:0.7em;
  }
html{
    
    font-size: 62.5%;
}

body{
    box-sizing: border-box;
    background-color: #e1e2e0;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
    
}

a{
    text-decoration: none;
    color: rgba(38, 70, 83, 0.8);
    padding-right: 2rem;
    transition: all .4s ease-in-out;
    
}


a:hover{
    -webkit-transform: translateY(-.2rem);
            transform: translateY(-.2rem);
}

.header {
    display: flex;
    flex-direction: column;
    height: 60vh;
    background-image: url(/static/media/mainbg.82772bc3.jpg);
    background-size: cover;
    background-position: center;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    align-items: center;

    justify-content: center; }

     
.heading{
    color: white;
    letter-spacing: .2rem; 
    font-weight: 200;
    font-size: 4rem;
    text-align: center;
    -webkit-animation: moveInTop .8s ease;
            animation: moveInTop .8s ease;
}

.heading-sm{
    font-size: 2rem;

}

.navbar{
  background-color: rgb(255, 255, 255); 
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
  height: 8vh;

  z-index: 20;
}
.navbarTop{
    background-color: #042350; 
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: space-between;
    height: 8.5vh;
    z-index: 20;
  }

.blog-preview a{
    text-decoration: none;
}

.content{
    font-size: 1.6rem;
    /* margin-top: 5vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
}

.form-content{
    font-size: 1.6rem;
    width: 70%;
    margin: 10vh auto;


   
}

.blog-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 2rem 2rem;
    grid-auto-rows: 35vh;
    margin: 5vh auto;
    width: 90%;
}

.blog{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 0px 0px;
    gap: 0px 0px;
    grid-template-areas:
    "text text text text"
    "text text text text"
    "delete delete delete delete";
    padding: 2rem;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    transition: all 0.4s ease-in-out;
}

.blog-text{
    padding: 1rem 1rem 2rem 1rem;
}

.blog-author{
    font-style: italic;
    font-size: 1.2rem
}

.delete { grid-area: delete; }
.text { grid-area: text; }

.blog:hover{
    -webkit-transform: translateY(-.5rem);
            transform: translateY(-.5rem);
}

.sent{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}


button{
    border: none;
    border-bottom: 2px solid #042350;
    padding: .5rem;
    color: black;
    font-weight: 500;
    transition: all .4s ease-in-out;
    cursor: pointer;
    background-color: #e1e2e0;
    outline: none;
}

.login{
    background-color: #fff;
    padding: 2rem;
    border: none;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-size: 1.6rem;
    margin-top: 5vh;
}

.add{
    background-color:transparent;
    /* padding: .7rem;
    border: none;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    */
    font-size: 1.6rem; 
}

.add__plus{
    margin-top: 5vh;
    margin-left: 5vw;
    padding: 1rem 1.5rem;
    font-size: 1.6rem;
    
}

.delete{
    background-color: #6d7d84;
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-size: 1.6rem;
    margin: 0 auto;
    margin-top: 10vh;
    width: 60%;
}

button:hover{
    background-color: #e33720;
    color: #fff;
    /* transform: translateY(-.1rem); */
}

form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: white;
    padding: 5rem;
    /* border-radius: 50% 20% / 10% 40%; */
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

input{
    border: none;
    border-radius: 10px;
    background-color: #e2eaeb;
    padding: 1rem;
    outline: none;
    color: #176285;
    margin: 1rem;
    text-align: center;
    width: inherit;
}

.btn-add{
    background-color: #6d7d84;
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-size: 1.6rem;
    margin: 2rem auto;
    width: 25%;
}

@-webkit-keyframes moveInTop{
    0%{
        opacity: 0;
        -webkit-transform: translateY(-10rem);
                transform: translateY(-10rem);
    }
    
    100%{
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes moveInTop{
    0%{
        opacity: 0;
        -webkit-transform: translateY(-10rem);
                transform: translateY(-10rem);
    }
    
    100%{
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
.embedContainer{
    height: 700px;
}

@media only screen and (max-width: 1000px){
    .embedContainer{
        height: 1000px;
    }
}
.apptContainer{
    text-align: center;
    background-color: #fbfcfd;
}
.apptTitle{
    font-family: 'Raleway';
}

@media only screen and (max-device-width: 480px) {
    .navbarTop{
        background-color: #042350; 
        display: flex;
        align-items: center;
        padding: 1rem;
        font-size: 0.7em; 
        justify-content: space-between;
        height: 15vh;
        z-index: 20;
      }

      .navbar{
        background-color: rgb(255, 255, 255); 
        display: flex;
        align-items: center;
        padding: 1rem;
        justify-content: space-between;
        height: 20vh;
      
        z-index: 20;
      }
     
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-weight:300;
    line-height: 2em;
    
}

.form{
    background-color:#ffffff;
    width:100%;
    padding:100px;
}
select{
    background-color:#ffffff;
    color:#4185ca;
    border:none;
    border-bottom:1px solid #4185ca;
    width:300px;
    margin:30px;
    outline:none;
}
.selectInputs, .inputBtns, #childInput{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width:100%;
    margin-bottom: 86px;
    
}

.calendar{
    width:100%;
    margin-top: 86px;
    text-align: center;
}
.form form{
    display:flex;
    flex-wrap:wrap;
    justify-content: space-evenly;
}
.btn{
    width:30px;
    border:none;
    border:1px solid #4185ca;
    border-radius: 1px;
    background-color:#191919;
    color:#4185ca;
    outline:none;
}
.btn:hover,select:hover{
    border-color: #4185ca;
    color:#4185ca;
}
.inputText{
    background-color:#ffffff;
    border:none;
    border-bottom:1px solid #4185ca;
    font-family: Montserrat ,sans-serif;
    color :#4185ca;
    margin:10px;
    width:250px;
    outline:none;
}
.inputbtn{
    display: flex;
    justify-items: space-around;
    align-items: center;
    margin: 25px;
}
#children {
    display: flex;
    flex-wrap: wrap;
}
@media screen and (max-width: 600px) {
    .form{
        position:relative;
        left: 50px;
        justify-content: center;
        padding-left:0px;
        }
    .selectInputs, .inputBtns, #childInput{
        display:flex;
        flex-direction:column;
        width:100%;
        text-align: left;
    }
    .inputText,select{
        width:60%;
    }
    .calendar{
        text-align:left;
        margin-left:20px;
        width:100%;
    }
    h2{
        font-size: 36px !important;
    }
    h2::before{
        width:50px !important;
    }
}
@media screen and (max-width: 300px) {
    .form{
        position:relative;
        left: 50px;
        justify-content: center;
        padding-left:0px;
        }
    .selectInputs, .inputBtns, #childInput{
        display:flex;
        flex-direction:column;
        width:100%;
        text-align: left;
    }
    .inputText,select{
        width:20%;
    }
    .calendar{
        text-align:left;
        margin-left:0px;
        width:100%;
    }
}   
@media only screen and (max-width:400px){
    h2{
        font-size: 25px !important;
        display:inline-block !important;
    }
    h2::before{
        height: 6px !important;
        width:15px !important;
    }
}
.carousel {
  height: 100%;
  /* background-color: aquamarine; */
  margin: auto;
}

.carousel-control-prev {
  height: 300px;
  visibility: hidden;
}

.carousel-control-next {
  height: 300px;
  visibility: hidden;
}

.carousel .carousel-control-prev-icon {
  visibility: hidden;
}

.carousel:hover .carousel-control-prev-icon {
  visibility: hidden;
}

.carousel .carousel-control-next-icon {
  visibility: hidden;
}

.carousel:hover .carousel-control-next-icon {
  visibility: hidden;
}

.carouselText h3 {
  font-family: 'LuloClean', sans-serif;
  color: white;
  font-size: 25pt;
  /* text-shadow: 1px 0 black, -1px 0 black, 0 1px black, 0 -1px black,
               1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black; */
}

.carouselText h4 {
  font-family: 'LuloClean', sans-serif;
  color: white;
  padding-bottom: 2%;
  /* text-shadow: 1px 0 black, -1px 0 black, 0 1px black, 0 -1px black,
               1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black; */
  font-size: 2vw;
}

.carouselText p {
  font-family: 'Arial', sans-serif;
  color: white;
}

/* @font-face {
  font-family: 'LuloClean';
  src: url(../../assets/fonts/LuloClean.ttf);
} */

video {
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
}

@media only screen and (max-width: 1200px) {
  .carousel-caption{
    display: none;
  }
  .carousel-indicators{
    display: none;
  }

}
@media only screen and (max-width: 920px){
  .bottomText{
    display: none;
  }
}
.fit {
  white-space: wrap;
}
.resHeader p{
  font-family: 'Raleway', sans-serif;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 11pt;
}
.carousel-indicators{
  display: none;
}
